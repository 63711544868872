import 'bootstrap/dist/js/bootstrap';

import { install as installPopovers, refresh as refreshPopovers } from "./popovers"

function refreshTooltips() {
  $('[data-toggle="tooltip"]').tooltip()
}

function refreshToasts() {
  $('.toast').toast({
    delay: 5000
  }).toast('show')
}

export function refresh() {
  refreshTooltips()
  refreshPopovers()
  refreshToasts()
}

export default function start() {
  installPopovers()

  $(document).on("turbolinks:load", () => {
    refresh()
  })
}