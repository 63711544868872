require("./stylesheets/application")


require("./jquery").expose()
require("./font-awesome").start()

import { start as startBootstrap, refresh as refreshBootstrap } from "./bootstrap"
import { start as startListItems } from "./list-items"
import { start as startSortable, refresh as refreshSortable} from "./sortable"

const App = {
  start() {
    startBootstrap()
    startListItems()
    startSortable()
  },

  refreshListeners() {
    refreshBootstrap()
    refreshSortable()
  }
}


window.App = App

App.start()