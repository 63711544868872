export default function start() {
  $(document).on("click", "*[data-behavior=cancel-items-form]", (e) => {
    const $this = $(e.target)

    const $cancel_target = $this.closest("[data-behavior=cancel-target]")

    $cancel_target.next().show()
    $cancel_target.remove()

    e.preventDefault()
    e.stopPropagation()
  })
}