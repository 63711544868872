import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faCheck,
  faCheckSquare,
  faExclamationTriangle,
  faGripLines,
  faHandPaper,
  faPencilAlt,
  faShareSquare,
  faSpinner,
  faTrash,
  faUserCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"

import {
  faSquare,
} from '@fortawesome/free-regular-svg-icons'


export function start() {
  library.add(
    faCheck,
    faCheckSquare,
    faExclamationTriangle,
    faGripLines,
    faHandPaper,
    faPencilAlt,
    faShareSquare,
    faSpinner,
    faTrash,
    faUserCheck,
    faUsers,

    faSquare,
  )

  dom.watch()
}