const Selector = {
  POPOVERS: '[data-toggle="popover"]',
  REMOTE: "*[data-behavior=remote-popover]",
  CLOSE_BUTTON: "*[data-behavior=close-popover]",
}

const DATA_KEY = "popover-is-open"

function prohibitRemoteRequestOnClose() {
  $(document).on("ajax:before", Selector.REMOTE, (e) => {
    const $this = $(e.target)
    const prohibitLoad = $this.data(DATA_KEY)

    return prohibitLoad == "true"
  })
  .on("show.bs.popover", Selector.REMOTE, (e) => {
    const $this = $(e.target)
    $this.data(DATA_KEY, "true")
  })
  .on("hide.bs.popover", Selector.REMOTE, (e) => {
    const $this = $(e.target)
    $this.data(DATA_KEY, "false")
  })
}

function installClosePopoverLinks() {
  $(document).on("click", Selector.CLOSE_BUTTON, (e) => {
    const $this = $(e.target)

    $this.closest(".popover").popover("hide")

    e.stopPropagation()
    e.preventDefault()
  })
}

export function install() {
  prohibitRemoteRequestOnClose()
  installClosePopoverLinks()
}

export function refresh() {
  $(Selector.POPOVERS).popover()
}