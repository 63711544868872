import 'jquery-sortablejs';
import { Selector } from "./config"

function updatePosition(e) {
  const $item = $(e.item)
  const position = e.newIndex

  const url = $item.data("sortable-update-url")
  const model = $item.data("sortable-update-model")
  const column = $item.data("sortable-update-column")

  const data = {}
  data[model] = {}
  data[model][column] = position

  $.ajax(url, {
    data: data,
    method: "PUT",
  })
}


export function refresh() {
  $(Selector.CONTAINER).each((i, container) => {
    const $container = $(container)

    const options = {
      axis: 'y',
      draggable: Selector.ITEM,
      handle: Selector.HANDLE,
      ghostClass: "sortable-ghost",
      dragClass: "sortable-dragging",
      disabled: $container.find(Selector.ITEM).length <= 1,
      onSort: (e) => {
        updatePosition(e)
      },
    }

    $container.sortable(options)
  })
}


export function start() {
  $(document).on("turbolinks:load", () => refresh())
}